const Index = () => {
  return (
    <div
      style={{
        backgroundColor: "papayawhip",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <a href="/science/" style={{ color: "rebeccapurple", fontSize: "5vw" }}>
        <h1>SCIENCE</h1>
      </a>
      <a
        href="/channel/archive/2020/07/this-is-a-slug/1234/"
        style={{ color: "green", fontSize: "1vw" }}
      >
        <h2>AN ARTICLE</h2>
      </a>
    </div>
  );
};

export default Index;
